
import { defineComponent, onBeforeMount, ref } from "vue";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);
import helper from "@/utils/helper";
import axios from "axios";
export default defineComponent({
  name: "joinUs",
  setup() {
    const dataSource = ref([]);
    const selectdIndex = ref(0);
    const carousel = ref(null)

    const onSwiper = (swiper: any) => {
      console.log(swiper);
    };
    const onSlideChange = (val: any) => {
      // swiper切换的时候执行的方法
      selectdIndex.value = val.activeIndex;
      console.log("slide change", val.activeIndex);
    };
    const carouselChange = (val: any) => {
      // swiper切换的时候执行的方法
      selectdIndex.value = val;
      console.log("11", val);
    };
    const slideClick = (val: any) => {
      // swiper切换的时候执行的方法
      selectdIndex.value = val;
      console.log("slide change", val);
    };
    const mobile = ref(false);

    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
      const url =
        process.env.NODE_ENV == "production"
          ? "/data/joinUs.json"
          : "/data/joinUs.json";
      axios.get(url).then((res: any) => {
        if (res.status == 200) {
          dataSource.value = res.data;
          console.log("dataSource.value", dataSource.value);
        }
      });
    });

    const touch = (e:any)=>{
      let clientX = e.clientX;
      let innerWidth = document.documentElement.clientWidth / 2;
      console.log(carousel.value);
      
      if(clientX > innerWidth){
        (carousel.value as any).next();
      }else{
        (carousel.value as any).prev();
      }
    }

    return {
      dataSource,
      onSwiper,
      onSlideChange,
      carouselChange,
      slideClick,
      selectdIndex,
      mobile,
      touch,
      carousel
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
});
